import React, { useState } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import SEO from "../components/seo"
import ScrollAnimation from "react-animate-on-scroll"
import { ProjectsStyle } from "../styles/projects"

const Projectos = () => {
  const data = useStaticQuery(graphql`
    query {
      all: allContentfulCarouselProjects(
        sort: { fields: publishedDate, order: DESC }
      ) {
        edges {
          node {
            title
            slug
            publishedDate(formatString: "D [de] MMMM [de] YYYY", locale: "pt")
            branch
            image {
              file {
                url
              }
            }
          }
        }
      }
      manutencao: allContentfulCarouselProjects(
        sort: { fields: publishedDate, order: DESC }
        filter: { branch: { eq: "manutencao" } }
      ) {
        edges {
          node {
            title
            slug
            publishedDate(formatString: "D [de] MMMM [de] YYYY", locale: "pt")
            branch
            image {
              file {
                url
              }
            }
          }
        }
      }
      eletricidade: allContentfulCarouselProjects(
        sort: { fields: publishedDate, order: DESC }
        filter: { branch: { eq: "eletricidade" } }
      ) {
        edges {
          node {
            title
            slug
            publishedDate(formatString: "D [de] MMMM [de] YYYY", locale: "pt")
            branch
            image {
              file {
                url
              }
            }
          }
        }
      }
      laser: allContentfulCarouselProjects(
        sort: { fields: publishedDate, order: DESC }
        filter: { branch: { eq: "laser" } }
      ) {
        edges {
          node {
            title
            slug
            publishedDate(formatString: "D [de] MMMM [de] YYYY", locale: "pt")
            branch
            image {
              file {
                url
              }
            }
          }
        }
      }
      dataJson {
        projectos {
          img {
            publicURL
          }
        }
      }
    }
  `)

  const [area, setArea] = useState(data.all.edges) // eslint-disable-line

  /* function handleChange(e) {
    if (e.target.value === "manutencao") {
      setArea(data.manutencao.edges)
      console.log(area)
    } else if (e.target.value === "eletricidade") {
      setArea(data.eletricidade.edges)
      console.log(area)
    } else if (e.target.value === "laser") {
      setArea(data.laser.edges)
      console.log(area)
    } else {
      setArea(data.all.edges)
    }
  } */

  return (
    <>
      <SEO
        title="Projectos"
        image="/images/projectos.jpg"
        imageWidth="1200"
        imageHeight="628"
        pathname="/projectos"
        description="Sicarpo - Fique a par dos nossos projectos mais recentes."
      />
      <ProjectsStyle>
        <div
          className="container"
          style={{
            backgroundImage: `url(${data.dataJson.projectos.img.publicURL})`,
          }}
        >
          <ScrollAnimation
            animateIn="fadeInDown"
            offset={0}
            duration={1.2}
            animateOnce={true}
            style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
          >
            <h1 className="title">Projetos realizados</h1>
            {/* <select
              id="select-area"
              className="select-area"
              onBlur={handleChange}
            >
              <option value="all">Todos</option>
              <option value="manutencao">Manutenção Industrial</option>
              <option value="eletricidade">Eletricidade Industrial</option>
              <option value="laser">Corte Laser</option>
            </select> */}
          </ScrollAnimation>
          <ol className="flex-container">
            {area.map((edge, index) => {
              let branch = edge.node.branch
              switch (branch) {
                case "manutencao":
                  branch = "Manutenção Industrial"
                  break
                case "eletricidade":
                  branch = "Eletricidade Industrial"
                  break
                case "laser":
                  branch = "Corte Laser"
                  break
                default:
                  break
              }
              return (
                <li key={"li" + index} className="flex-item">
                  <Link
                    key={"1link" + index}
                    className="flex-item-content"
                    to={`/projectos/${edge.node.slug}`}
                  >
                    <h3>{branch}</h3>
                    <div key={"img" + index} className="image">
                      <img
                        src={edge.node.image.file.url}
                        alt={edge.node.title}
                      />
                    </div>
                    <h2 key={"h2" + index}>{edge.node.title}</h2>
                    <p key={"p" + index}>{edge.node.publishedDate}</p>
                  </Link>
                  <Link
                    key={"2link" + index}
                    className="flex-button"
                    to={`/projectos/${edge.node.slug}`}
                  >
                    <i className="material-icons">add</i>
                  </Link>
                </li>
              )
            })}
          </ol>
        </div>
      </ProjectsStyle>
    </>
  )
}

export default Projectos
