import styled from "styled-components"
import { colors } from "./variables"

export const ProjectsStyle = styled.div`
  .container {
    height: 100%;
    min-height: 60vh;
    padding: 20vh 10vw 20vh 10vw;
    background-size: cover;
    background-position-x: center;
    color: #ffffff;

    & > * {
      position: relative;
      z-index: 2;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }

    .title {
      color: #ffffff;
      text-transform: uppercase;
      font-family: "Barlow", sans-serif;
      font-size: 6rem;
      font-weight: 700;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.3;
      letter-spacing: 1.04px;
      margin-bottom: 25px;
    }

    ol {
      list-style: none;
      padding-inline-start: 0px;

      li {
        margin: 15px 0 15px 0;
        padding: 30px 10px 30px 10px;
        transition: background-color 0.3s ease;
        -o-transition: background-color 0.3s ease;
        -moz-transition: background-color 0.3s ease;
        -webkit-transition: background-color 0.3s ease;

        &:hover {
          background-color: rgba(102, 102, 102, 0.5);
        }
      }
    }

    .flex-container {
      /* FLEX */
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: space-between;
      align-items: center;
      margin: 0 -5px;
      /* FLEX */

      .flex-item {
        position: relative;
        display: flex;
        flex-basis: 20%;
        height: fit-content;
        background-color: ${colors.darkGreyT};
        margin: 15px;

        &:hover > .flex-item-content h2,
        &:hover > .flex-item-content p {
          color: ${colors.orange};
        }
        &:hover .image img {
          transform: scale(1.15);
        }
        &:hover .flex-button {
          background-color: ${colors.orangeDark};
          transform: scale(1.05);
        }
      }
      .flex-item-content {
        display: grid;
        grid-template-rows: auto auto 1fr auto;
        align-items: center;
        justify-items: center;
        grid-row-gap: 15px;
      }
      .image,
      .flex-item-content h3,
      .flex-item-content h2,
      .flex-item-content p {
        width: 90%;
        margin: 0 5%;
        transition: color 0.25s ease;
      }

      .flex-item-content h3 {
        font-size: 1.3rem;
        text-align: left;
        color: ${colors.lightGrey};
      }
      .flex-item-content h2 {
        font-size: 1.6rem;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .flex-item-content p {
        font-size: 1.6rem;
        align-self: flex-end;
      }
      .image {
        overflow: hidden;
        height: 100%;
        max-height: 180px;
      }
      .image img {
        width: 100%;
        transition: transform 0.25s ease;
      }
      .flex-button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: calc((36 / 3) * -1px);
        right: calc((42 / 3) * -1px);
        padding: 0;
        margin: 0;
        height: 36px;
        width: 42px;
        border: 0;
        background-color: ${colors.orange};
        box-shadow: -3px 6px 8.5px 0 rgba(0, 0, 0, 0.13);
        color: ${colors.whiteBg};
        transition: all 0.25s ease;

        .material-icons {
          font-size: 1.8rem;
          transform: translateY(1px);
        }
      }
    }
  }

  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: #f5f5f5;
    box-shadow: 0 0 0px 1000px #343434 inset;
    -webkit-box-shadow: 0 0 0px 1000px #343434 inset;
    padding-left: 0.5rem;
  }
  .select-area {
    background: none;
    border: none;
    border-bottom: 1.2px solid #cbcbca;
    margin: 0 0px 25px 30px;
    height: 2.5rem;

    font-family: "Barlow", sans-serif;
    font-size: 1.8rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.79;
    letter-spacing: normal;
    text-align: left;
    color: #cbcbca;

    &:focus {
      outline: 0;
      background: none;
    }

    ::placeholder {
      font-family: "Barlow", sans-serif;
      font-size: 1.5rem;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.79;
      letter-spacing: normal;
      text-align: left;
      color: #cbcbca;
    }

    option {
      background-color: ${colors.darkGrey};
    }
  }

  @media screen and (min-width: 2560px) {
    .container {
      .title {
        font-size: 8rem;
      }
      .flex-container {
        .flex-item-content h3 {
          font-size: 1.6rem;
        }
        .flex-item-content h2 {
          font-size: 2rem;
        }
        .flex-item-content p {
          font-size: 2rem;
        }
      }
    }
  }
  @media screen and (min-width: 1921px) and (max-width: 2559px) {
    .container {
      .title {
        font-size: calc(60px + 20 * (100vw - 1920px) / 640);
      }
      .flex-container {
        .flex-item-content h3 {
          font-size: calc(13px + 3 * (100vw - 1920px) / 640);
        }
        .flex-item-content h2 {
          font-size: calc(16px + 4 * (100vw - 1920px) / 640);
        }
        .flex-item-content p {
          font-size: calc(16px + 4 * (100vw - 1920px) / 640);
        }
      }
    }
  }

  @media screen and (max-width: 1400px) {
    .container .flex-container .flex-item {
      flex-basis: 28%;
    }
  }
  @media screen and (max-width: 1200px) {
    .container .flex-container .flex-item {
      flex-basis: 40%;
    }
  }
  @media screen and (max-width: 900px) {
    .container .title {
      font-size: 3rem;
    }
    .select-area {
      font-size: 1.4rem;
      height: 1.8rem;
      transform: translateY(-3px);
    }
  }
  @media screen and (max-width: 767px) {
    .container {
      padding: 20vh 30px 20vh 30px;
    }
    .container .title {
      font-size: 2.4rem;
      line-height: 30px;
      margin-bottom: 12.5px;
    }
    .select-area {
      font-size: 1.2rem;
      height: 1.8rem;
      transform: translateY(2px);
    }
    .container .flex-container {
      padding: 0;
    }
    .container .flex-container .flex-item {
      flex-basis: 100%;
      height: unset;
      margin-left: 0;
      margin-right: 0;
    }
    .container .flex-container .flex-item .flex-item-content {
      grid-template-rows: auto 1fr 0.5fr;
    }
    .container .flex-container .flex-item .flex-item-content h2 {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 535px) {
    .select-area {
      margin-left: 0px;
    }
    .container .title {
      margin-right: 30px;
    }
  }
  @media screen and (max-width: 400px) {
    .container .title {
      margin-right: 0px;
    }
  }
`
